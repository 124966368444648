export default [
  {
    text: "Client",
    value: "client",
    width: "54px",
    cellClass: "px-2",
    class: "px-2",
    order: 0,
    hidable: false,
    hidden: false,
  },
  {
    text: "Starred",
    icon: "fad fa-star",
    value: "isStarred",
    cellClass: "pl-0",
    sortable: false,
    align: "left",
    class: "pl-0",
    width: "40px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  { text: "Name", value: "name", order: 2, hidable: false, hidden: false },
  {
    text: "Spaces & Equips",
    value: "spaceCount",
    sortable: true,
    width: "132px",
    order: 3,
    hidable: true,
    hidden: false,
  },
];
