<template>
  <v-sheet color="white" elevation="2" rounded="lg" height="220px" width="100%" class="pa-2">
    <span class="fs-16px font-weight-bold ml-2 mr-2">Projects Count</span>
    <v-scroll-y-transition mode="out-in">
      <div
        v-if="loading"
        key="loading-section"
        style="height: 100%"
        class="d-flex flex-column justify-center align-center"
      >
        <v-progress-circular indeterminate color="orange" size="40" width="3"></v-progress-circular>
        <p class="font-weight-bold mt-4">Loading chart data...</p>
      </div>
      <apexcharts
        v-else
        key="chart-section"
        type="donut"
        height="180px"
        :options="chartOptions"
        :series="series"
      ></apexcharts>
    </v-scroll-y-transition>
  </v-sheet>
</template>
<script>
import projectsService from "../../Projects/services/projects-service";
export default {
  name: "project-count-pie-chart",
  props: {
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      chartOptions: {
        chart: {
          type: "donut",
          height: "250px",
          toolbar: {
            show: true,
          },
          events: {
            legendClick: ({ opts }, seriesIndex) => {
              this.$router.push("/" + opts.links[seriesIndex]);
            },
            markerClick: ({ opts }, seriesIndex) => {
              this.$log(opts);
              this.$router.push("/" + opts.links[seriesIndex]);
            },
            dataPointSelection: (_, { opts }, config) => {
              this.$router.push("/" + opts.links[config.dataPointIndex]);
            },
          },
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Cascadia Code",
            fontWeight: "medium",
            colors: undefined,
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: true,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.24,
            },
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.16,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "22px",
                  fontWeight: 600,
                  color: undefined,
                  formatter: function (val) {
                    return val;
                  },
                },
                value: {
                  show: true,
                  fontSize: "18px",
                  fontWeight: 700,
                  offsetY: -2,
                  color: "rgba(42, 54, 59, 0.54)",
                  fontFamily: "Cascadia Code",
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "TOTAL",
                  fontSize: "14px",
                  fontFamily: "Karla, sans-serif",
                  fontWeight: "Bold",
                  color: "rgba(42, 54, 59, 0.87)",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        colors: [
          this.$vuetify.theme.themes.light.info,
          this.$vuetify.theme.themes.light.chart.amber,
          this.$vuetify.theme.themes.light.chart.green,
          this.$vuetify.theme.themes.light.chart.pink,
          this.$vuetify.theme.themes.light.chart.brown,
        ],
        labels: ["Leads", "Opportunities", "Active", "Closed", "Archived"],
        links: [
          "leads",
          "opportunities",
          "active-projects",
          "closed-projects",
          "archived-projects",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          fontFamily: "Karla",
          fontWeight: "medium",
          offsetY: -10,
          formatter: function (seriesName, opts) {
            return [seriesName, "<b>(" + opts.w.globals.series[opts.seriesIndex] + ")</b>"];
          },
          labels: {
            useSeriesColors: true,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 8,
          },
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return value + " Projects";
            },
          },
        },
      },
      stages: {
        lead: 1,
        opportunity: 1,
        active: 1,
        closed: 1,
        archived: 1,
      },
    };
  },
  computed: {
    series() {
      return [
        this.stages.lead,
        this.stages.opportunity,
        this.stages.active,
        this.stages.closed,
        this.stages.archived,
      ];
    },
  },
  created() {
    projectsService
      .stagesCount()
      .then((res) => {
        this.stages = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  },
};
</script>
