import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "SpaceTemplates";
export default {
  get() {
    return Api().get(baseUrl);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  queryLite(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/Lite?" + qParams.toString());
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  GenerateFromSpace(entity) {
    return Api().post(baseUrl + "/GenerateFromSpace", entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  restore(id) {
    return Api().put(baseUrl + `/${id}/restore`);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  getRecent(mode, count) {
    return Api().get(`${baseUrl}/Recent?Count=${count}&mode=${mode}`);
  },
  getTemplateAreaGroups(id) {
    return Api().get(baseUrl + `/${id}/AreaGroups`);
  },
  getTemplateById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getTemplateState(id) {
    return Api().get(baseUrl + `/${id}/Stats`);
  },
  getTemplateItems(id, options, type) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(`${baseUrl}/${id}/Stats/${type}?${qParams.toString()}`);
  },
  checkIfSpaceTemplateNameExist({ name }) {
    return Api().get(`${baseUrl}/Space/Name/Exist?SpaceName=${name}`);
  },
  cloneSpaceTemplate({ name, imageUrl, spaceTemplateId }) {
    return Api().post(`${baseUrl}/${spaceTemplateId}/Clone`, { name, imageUrl });
  },
  exportExcel(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/Export?" + qParams.toString());
  },
  getActivityLog(id, options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + `/${id}/ActivityLogs?` + qParams.toString());
  },
  addActivityLog(id, note) {
    return Api().post(baseUrl + `/${id}/ActivityLogs`, {
      notes: note,
      type: 2,
    });
  },
};
