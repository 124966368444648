<template>
  <v-sheet
    color="white"
    min-height="400px"
    max-height="400px"
    elevation="2"
    height="100%"
    width="100%"
    rounded="lg"
    class="pa-3"
  >
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex align-center">
        <span class="fs-16px font-weight-bold ml-2 mr-2"
          ><i class="fad fa-user-tie secondary--text mr-2" small></i>Top clients by:</span
        >
        <v-select
          class="type-select-with-bg"
          flat
          hide-details
          dense
          style="max-width: 150px"
          :items="clientType"
          v-model="options.TopQueryType"
          solo
        ></v-select>
      </div>
    </div>

    <v-scroll-y-transition mode="out-in">
      <div
        v-if="loading"
        key="loading-section"
        style="height: 100%"
        class="d-flex flex-column justify-center align-center"
      >
        <v-progress-circular indeterminate color="orange" size="40" width="3"></v-progress-circular>
        <p class="font-weight-bold mt-4">Loading data...</p>
      </div>
      <apexcharts
        v-else
        style="margin-top: -1rem"
        key="chart-section"
        type="bar"
        height="350px"
        :options="chartOptions"
        :series="series"
      ></apexcharts>
    </v-scroll-y-transition>
  </v-sheet>
</template>
<script>
import clientsService from "../../Companies/Clients/services/clients-service";
import enums from "../../../plugins/enums";

export default {
  name: "client-count-chart",
  props: {
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      clientType: enums.CLIENT_TYPE,
      options: {
        TopQueryType: 2,
        count: 10,
      },
      loading: true,
      chartOptions: {
        chart: {
          type: "bar",
          height: "auto",
          offsetY: 10,
        },
        plotOptions: {
          colors: ["#ff8a00"],
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        fill: {
          type: "image",
          opacity: 0.87,
          image: {
            src: ["/img/gradient-chart-bg5.jpg"],
            width: "100%",
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: "12px",
            fontFamily: "Karla",
            colors: ["#fff"],
          },
        },
        events: {
          legendClick: ({ opts }, seriesIndex) => {
            alert("");
            this.$router.push("/" + opts.links[seriesIndex]);
          },
          click: (event, chartContext, config) => {
            alert("");
            this.$log(config.dataPointIndex);
            // perform action based on clicked column
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: false,
            showDuplicates: false,
            trim: false,
            style: {
              fontSize: "10px",
              fontFamily: "Karla",
            },
          },
        },
        yaxis: {
          labels: {
            align: "right",
            minWidth: 0,
            maxWidth: 116,
            style: {
              fontSize: "12px",
              fontFamily: "Karla",
            },
          },
        },
      },
      series: [],
    };
  },

  watch: {
    options: {
      handler() {
        clientsService
          .top(this.options)
          .then((res) => {
            this.chartOptions.xaxis.categories = res.data.map((elm) => elm.client || elm.name);
            this.series = [
              {
                name: "Count",
                data: res.data.map((elm) => elm.value),
              },
            ];
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
