export default [
  {
    text: "Name",
    value: "name",
    width: "80px",
    sortable: false,
    hidable: false,
    hidden: false,
  },
  {
    text: "Abbrv.",
    value: "abbreviatedName",
    order: 2,
    hidable: true,
    hidden: false,
    width: "90px",
  },
];
