export default [
  {
    text: "Client",
    value: "client",
    width: "54px",
    cellClass: "px-2",
    class: "px-2",
    order: 2,
    hidable: false,
    hidden: false,
  },
  { text: "Name", value: "name", order: 3, hidable: false, hidden: false },
  {
    text: "Spaces & Equips",
    value: "spaceCount",
    sortable: true,
    width: "132px",
    order: 5,
    hidable: true,
    hidden: false,
  },
];
