<template>
  <v-card
    v-if="value != null"
    rounded="lg"
    elevation="2"
    style="height: 100%"
    class="d-flex align-center"
  >
    <v-row style="flex-wrap: nowrap">
      <v-col cols="auto">
        <v-card-title class="pb-2 font-weight-bold">
          <span class="fs-28px mr-2 mono-font">{{ currentScopeValue }}</span>
          <span class="opacity-87">{{ title }}</span>
        </v-card-title>
        <v-card-text class="pt-0">
          <h3 class="d-flex align-center" style="flex: none">
            <v-select
              style="width: 85px; max-width: 85px"
              class="type-select-with-bg"
              solo
              flat
              dense
              hide-details
              v-model="filter"
              :items="filterOptions"
            >
            </v-select>
            <span class="font-weight-medium mx-1 text--secondary">this</span>
            <v-btn-toggle v-model="scope" dense color="orange accent-3" mandatory>
              <v-btn
                value="week"
                small
                style="font-weight: 600; text-transform: none"
                class="fs-12px"
                >Week</v-btn
              >
              <v-btn
                value="month"
                small
                style="font-weight: 600; text-transform: none"
                class="fs-12px"
                >Month</v-btn
              >
              <v-btn
                value="year"
                small
                style="font-weight: 600; text-transform: none"
                class="fs-12px"
                >Year</v-btn
              >
            </v-btn-toggle>
          </h3>
        </v-card-text>
      </v-col>
      <v-col class="d-flex align-center justify-end pr-9">
        <!-- <i class="fad fa-lightbulb-on fa-3x" style="--fa-primary-color: #ffc107; --fa-secondary-color: orange"></i> -->
        <i
          class="fad"
          :class="icon"
          style="font-size: 2.5rem; --fa-primary-color: orange; --fa-secondary-color: #090d0f"
        ></i>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "overview-entry-card",
  props: {
    value: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      filterOptions: [
        {
          text: "Created",
          value: "created",
        },
        {
          text: "Updated",
          value: "updated",
        },
      ],
      filter: "created",
      scope: "month",
    };
  },
  computed: {
    currentScopeValue() {
      if (this.value == null) return 0;
      return this.value[this.filter][this.scope];
    },
  },
  created() {},
};
</script>
