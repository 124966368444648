<template>
  <v-container fluid>
    <h2 class="d-flex align-center font-weight-medium mb-3 mt-1">
      {{ timeGreetings }}
      <span class="text--primary font-weight-black ml-2">{{ loggedInUser.firstName }}!</span>
      <span class="deep-purple--text ml-2" v-if="loggedInUser.firstName == 'Joey'">
        <i
          v-if="rand == 1"
          class="fad fa-user-astronaut fa-bounce"
          style="--fa-animation-duration: 1.25s"
        ></i>
        <i v-else class="fad fa-ufo fa-beat" style="--fa-animation-duration: 1.25s"></i>
      </span>
      <span class="green--text ml-2" v-else-if="loggedInUser.firstName == 'Don'">
        <i
          v-if="rand == 1"
          class="fad fa-umbrella-beach fa-bounce"
          style="--fa-animation-duration: 1.5s"
        ></i>
        <i v-else class="fad fa-island-tropical fa-beat" style="--fa-animation-duration: 1.5s"></i>
      </span>
    </h2>
    <div v-if="$has(perms.Dashboard.ViewAllWidgets)" key="has-widget-perms">
      <v-row>
        <v-col cols="12" md="4">
          <projects-count-pie-chart></projects-count-pie-chart>
        </v-col>
        <v-scroll-y-transition mode="out-in">
          <v-col
            cols="12"
            md="8"
            class="d-flex align-center justify-center"
            v-if="loadingSystemOverviewStats"
            key="loading-section"
          >
            <div style="height: 100%" class="d-flex flex-column justify-center align-center">
              <v-progress-circular
                indeterminate
                color="orange"
                size="40"
                width="3"
              ></v-progress-circular>
              <p class="font-weight-bold mt-4">Loading System Overview ...</p>
            </div>
          </v-col>
          <v-col cols="12" md="8" class="d-flex align-center" v-else>
            <v-row style="height: 100%" class="pb-4 pb-md-0">
              <v-col cols="12" md="6" class="pa-3 pt-md-0">
                <overview-entry-card
                  v-model="systemOverviewStats.projects"
                  title="Opportunities"
                  icon="fa-lightbulb-on"
                ></overview-entry-card>
              </v-col>
              <v-col cols="12" md="6" class="pa-3 pt-md-0">
                <overview-entry-card
                  v-model="systemOverviewStats.equipment"
                  title="Equipment"
                  icon="fa-microchip"
                ></overview-entry-card>
              </v-col>
              <v-col cols="12" md="6" class="pa-3 pb-md-0">
                <overview-entry-card
                  v-model="systemOverviewStats.spaces"
                  title="Project Spaces"
                  icon="fa-table-pivot"
                ></overview-entry-card>
              </v-col>
              <v-col cols="12" md="6" class="pa-3 pb-md-0">
                <overview-entry-card
                  v-model="systemOverviewStats.spaceTemplates"
                  title="Space Templates"
                  icon="fa-code"
                ></overview-entry-card>
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <client-count-chart></client-count-chart>
        </v-col>
        <!-- <v-col cols="12" md="4">
          <phase-by-deadline></phase-by-deadline>
        </v-col> -->
        <v-col cols="12" md="6">
          <v-sheet
            rounded="lg"
            class="pa-3 d-flex flex-column"
            :elevation="2"
            min-height="400"
            max-height="400"
          >
            <h3 class="mb-2 d-flex align-center">
              <span class="type-label"
                ><i class="fad fa-star secondary--text mr-2" small></i>My Starred Projects
              </span>
            </h3>
            <v-data-table
              style="height: 100%; flex: 1 1 auto"
              dense
              :headers="starredProjectsHeader"
              :items="starredProjects.data || []"
              :loading="starredProjects.loading"
              class="dashboard-card-table table-sticky-header"
              hide-default-footer
              disable-sort
              @click:row="starredProjectClicked"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'far fa-arrow-to-left',
                lastIcon: 'far fa-arrow-to-right',
                prevIcon: 'far fa-angle-left',
                nextIcon: 'far fa-angle-right',
                itemsPerPageOptions: [15, 30, 50, 100],
              }"
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>

              <template v-slot:[`header.isStarred`]="{ header }">
                <i :class="header.icon"></i>
              </template>

              <template v-slot:[`item.isStarred`]="{ item }">
                <span>
                  <i
                    :class="{
                      'fas fa-star orange--text': item.isStarred,
                      'fad fa-star secondary--text': !item.isStarred,
                    }"
                    small
                  ></i>
                </span>
              </template>

              <template v-slot:[`item.client`]="{ item }">
                <v-tooltip top nudge-top="-16px">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="pa-2"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="viewClient(item.client.id)"
                    >
                      <v-img
                        v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                        :src="item.client.logoUrl"
                        max-height="28"
                        max-width="54"
                        position="left center"
                        contain
                      >
                      </v-img>
                      <v-img
                        v-else
                        src="/img/DNA_Symbol.png"
                        max-height="28"
                        max-width="54"
                        position="left center"
                        contain
                      >
                      </v-img>
                    </div>
                  </template>
                  <span>{{ item.client.name }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.name`]="{ item }">
                <div class="my-2">
                  <project-status :status="item.status" small show-parent></project-status>
                  <h4 class="mt-1">{{ item.fullName }}</h4>
                </div>
              </template>
              <template v-slot:[`item.spaceCount`]="{ item }">
                <div class="d-flex" style="gap: 0.5rem">
                  <space-count :count="item.spaceCount" />
                  <equipment-count :count="item.equipmentCount" />
                </div>
              </template>

              <template v-slot:no-data>
                <img width="500" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
              <template v-slot:loading>
                <v-progress-circular
                  class="mt-8"
                  indeterminate
                  color="orange"
                  size="40"
                  width="3"
                ></v-progress-circular>
                <p class="font-weight-bold mt-4">Loading data...</p>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet
            rounded="lg"
            class="pa-3 d-flex flex-column"
            :elevation="2"
            min-height="400"
            max-height="400"
          >
            <h3 class="mb-2 d-flex align-center">
              <span class="type-label">
                <router-link to="/active-projects"
                  ><i class="fad fa-folder-open secondary--text mr-2" small></i
                  >Projects</router-link
                >
              </span>
              <span class="type-recently-label mr-1">Recently</span>
              <v-select
                style="width: 100px; max-width: 100px"
                class="type-select-with-bg"
                solo
                flat
                dense
                hide-details
                v-model="projects.mode"
                :items="PROJECTS_RECENT_TYPE | EnumToList"
              >
              </v-select>
            </h3>
            <v-data-table
              style="height: 100%; flex: 1 1 auto"
              dense
              :headers="projectHeader"
              :items="recentProjects || []"
              :loading="projects.loading"
              class="dashboard-card-table table-sticky-header"
              hide-default-footer
              disable-sort
              @click:row="projectClicked"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'far fa-arrow-to-left',
                lastIcon: 'far fa-arrow-to-right',
                prevIcon: 'far fa-angle-left',
                nextIcon: 'far fa-angle-right',
                itemsPerPageOptions: [15, 30, 50, 100],
              }"
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>

              <template v-slot:[`header.jiraCode`]="{ header }">
                <i :class="header.icon"></i>
                {{ header.text.toUpperCase() }}
              </template>

              <template v-slot:[`item.client`]="{ item }">
                <v-tooltip top nudge-top="-16px">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="pa-2"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="viewClient(item.client.id)"
                    >
                      <v-img
                        v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                        :src="item.client.logoUrl"
                        max-height="28"
                        max-width="54"
                        position="left center"
                        contain
                      >
                      </v-img>
                      <v-img
                        v-else
                        src="/img/DNA_Symbol.png"
                        max-height="28"
                        max-width="54"
                        position="left center"
                        contain
                      >
                      </v-img>
                    </div>
                  </template>
                  <span>{{ item.client.name }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.name`]="{ item }">
                <div class="my-2">
                  <project-status :status="item.status" small show-parent></project-status>
                  <h4 class="mt-1">{{ item.fullName }}</h4>
                </div>
              </template>
              <template v-slot:[`item.spaceCount`]="{ item }">
                <div class="d-flex" style="gap: 0.5rem">
                  <space-count :count="item.spaceCount" />
                  <equipment-count :count="item.equipmentCount" />
                </div>
              </template>

              <template v-slot:no-data>
                <img width="500" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
              <template v-slot:loading>
                <v-progress-circular
                  class="mt-8"
                  indeterminate
                  color="orange"
                  size="40"
                  width="3"
                ></v-progress-circular>
                <p class="font-weight-bold mt-4">Loading data...</p>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet
            rounded="lg"
            class="pa-3 d-flex flex-column"
            :elevation="2"
            min-height="400"
            max-height="400"
          >
            <h3 class="mb-2 d-flex align-center">
              <span class="type-label">
                <router-link to="/space-templates"
                  ><i class="fad fa-plug secondary--text mr-2" small></i>Equipment</router-link
                >
              </span>
              <span class="type-recently-label mr-1">Recently</span>
              <v-select
                style="width: 100px; max-width: 100px"
                class="type-select-with-bg"
                solo
                flat
                dense
                hide-details
                v-model="equipment.mode"
                :items="EQUIPMENTS_RECENT_TYPE | EnumToList"
              >
              </v-select>
            </h3>
            <v-data-table
              dense
              :headers="equipmentHeader"
              :items="recentEquipment"
              :loading="equipment.loading"
              @click:row="equipmentClicked"
              disable-sort
              class="dashboard-card-table table-sticky-header"
              style="height: 100%; flex: 1 1 auto"
              hide-default-footer
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>

              <template v-slot:[`item.name`]="{ item }">
                <v-tooltip right z-index="999" nudge-right="-4px">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" :class="'equipment-image-' + item.id">
                      <div class="equipment-img">
                        <div
                          style="height: 100%; width: 100%"
                          v-viewer
                          @click.stop
                          v-if="item.imageUrl != null && item.imageUrl != ''"
                        >
                          <img
                            :key="item.id + '_img'"
                            :src="item.imageUrl"
                            height="100%"
                            width="100%"
                          />
                        </div>
                        <i
                          v-else-if="item.category != null"
                          :class="'fad ' + item.category.icon"
                        ></i>
                        <i v-else :class="'fad fa-plug'"></i>
                      </div>
                    </div>
                  </template>
                  <span>
                    <v-img
                      v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                      class="img"
                      :src="item.imageUrl"
                      width="250px"
                      height="250px"
                      contain
                    ></v-img>
                    <i
                      v-else-if="item.category != null"
                      :class="'fad ' + item.category.icon"
                      style="font-size: 32px; padding: 2rem"
                    ></i>
                    <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
                  </span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.category`]="{ item }">
                <v-row no-gutters>
                  <v-col sm="auto" class="d-flex justify-content-start align-items-center">
                    <i
                      v-if="item.category != null"
                      :class="'equipment-icon fad ' + item.category.icon"
                    ></i>
                    <i v-else :class="'equipment-icon fad fa-plug'"></i>
                  </v-col>
                  <v-col class="d-flex align-center">
                    <span class="category-name" v-if="item.category">{{ item.category.name }}</span>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:[`item.tag`]="{ item }">
                <div class="equipment-tag font-weight-bold">{{ item.tag }}</div>
              </template>

              <template v-slot:[`item.manufacture.name`]="{ item }">
                <div
                  v-if="item.manufacture != null"
                  class="d-flex align-center"
                  style="gap: 0.5rem"
                >
                  <v-tooltip right z-index="999" nudge-right="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div class="company-logo-mini">
                          <div
                            style="height: 100%; width: 100%"
                            v-viewer
                            @click.stop
                            v-if="
                              item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''
                            "
                          >
                            <img
                              :key="item.manufacture.id + '_img'"
                              :src="item.manufacture.logoUrl"
                              height="100%"
                              width="100%"
                              style="object-fit: contain"
                            />
                          </div>
                          <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                            <img
                              :key="item.manufacture.id + '_img'"
                              src="/img/DNA_Symbol.png"
                              height="100%"
                              width="100%"
                              style="object-fit: contain"
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                    <span>
                      <v-img
                        v-if="item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''"
                        :src="item.manufacture.logoUrl"
                        height="250px"
                        width="250px"
                        contain
                      ></v-img>
                      <v-img
                        v-else
                        src="/img/DNA_Symbol.png"
                        height="250px"
                        width="250px"
                        contain
                      ></v-img>
                    </span>
                  </v-tooltip>
                  <h5 class="ma-0">{{ item.manufacture.name }}</h5>
                </div>
                <div v-else class="text--disabled">N/A</div>
              </template>

              <template v-slot:[`item.productFamily.name`]="{ item }">
                <h5 class="ma-0" v-if="item.productFamily != null">
                  {{ item.productFamily.name }}
                </h5>
                <div v-else class="text--disabled">N/A</div>
              </template>

              <template v-slot:[`item.model`]="{ item }">
                <div class="equipment-model mono-font font-weight-bold">{{ item.model }}</div>
              </template>
              <template v-slot:no-data>
                <img width="500" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
              <template v-slot:loading>
                <v-progress-circular
                  class="mt-8"
                  indeterminate
                  color="orange"
                  size="40"
                  width="3"
                ></v-progress-circular>
                <p class="font-weight-bold mt-4">Loading data...</p>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet
            rounded="lg"
            class="pa-3 d-flex flex-column"
            :elevation="2"
            min-height="400"
            max-height="400"
          >
            <h3 class="mb-2 d-flex align-center">
              <span class="type-label">
                <router-link to="/manufacturers"
                  ><i class="fad fa-industry-windows secondary--text mr-2" small></i
                  >Manufactures</router-link
                >
              </span>
              <span class="type-recently-label mr-1">Recently</span>
              <v-select
                style="width: 100px; max-width: 100px"
                class="type-select-with-bg"
                solo
                flat
                dense
                hide-details
                v-model="manufacture.mode"
                :items="EQUIPMENTS_RECENT_TYPE | EnumToList"
              >
              </v-select>
            </h3>
            <v-data-table
              dense
              :headers="manufactureHeader"
              :items="recentManufactures"
              :loading="manufacture.loading"
              @click:row="manufactureClicked"
              disable-sort
              class="dashboard-card-table table-sticky-header"
              style="height: 100%; flex: 1 1 auto"
              hide-default-footer
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div class="d-flex align-center" style="gap: 0.5rem">
                  <v-tooltip right z-index="999" nudge-right="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div class="company-logo">
                          <div
                            style="height: 100%; width: 100%"
                            v-viewer
                            @click.stop
                            v-if="item.logoUrl != null && item.logoUrl != ''"
                          >
                            <img
                              :key="item.id + '_img'"
                              :src="item.logoUrl"
                              height="100%"
                              width="100%"
                            />
                          </div>
                          <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                            <img
                              :key="item.id + '_img'"
                              src="/img/DNA_Symbol.png"
                              height="100%"
                              width="100%"
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                    <span>
                      <v-img
                        v-if="item.logoUrl != null && item.logoUrl != ''"
                        :src="item.logoUrl"
                        height="250px"
                        width="250px"
                        contain
                      ></v-img>
                      <v-img
                        v-else
                        src="/img/DNA_Symbol.png"
                        height="250px"
                        width="250px"
                        contain
                      ></v-img>
                    </span>
                  </v-tooltip>
                  <h4 class="ma-0 deep-orange--text" v-if="item.name == specialManufacturerName">
                    {{ item.name }}
                  </h4>
                  <h4 class="ma-0" v-else>{{ item.name }}</h4>
                </div>
              </template>
              <template v-slot:no-data>
                <img width="500" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
              <template v-slot:loading>
                <v-progress-circular
                  class="mt-8"
                  indeterminate
                  color="orange"
                  size="40"
                  width="3"
                ></v-progress-circular>
                <p class="font-weight-bold mt-4">Loading data...</p>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6">
          <v-sheet
            rounded="lg"
            class="pa-3 d-flex flex-column"
            :elevation="2"
            min-height="400"
            max-height="400"
          >
            <h3 class="mb-2 d-flex align-center">
              <span class="type-label">
                <router-link to="/space-templates"
                  ><i class="fad fa-code secondary--text mr-2" small></i>Space
                  Templates</router-link
                >
              </span>
              <span class="type-recently-label mr-1">Recently</span>
              <v-select
                style="width: 100px; max-width: 100px"
                class="type-select-with-bg"
                solo
                flat
                dense
                hide-details
                v-model="spaceTemplates.mode"
                :items="EQUIPMENTS_RECENT_TYPE | EnumToList"
              >
              </v-select>
            </h3>
            <v-data-table
              dense
              :headers="spaceTemplatesHeader"
              :items="recentSpaceTemplates"
              :loading="spaceTemplates.loading"
              @click:row="templateClicked"
              disable-sort
              class="dashboard-card-table table-sticky-header"
              style="height: 100%; flex: 1 1 auto"
              hide-default-footer
            >
              <template v-slot:progress>
                <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
              </template>

              <template v-slot:[`item.imageUrl`]="{ item }">
                <v-badge color="secondary" bordered left offset-x="20px" offset-y="34px">
                  <template v-slot:badge>
                    <i class="fal fa-code mb-0" style="font-size: 10px"></i>
                  </template>
                  <v-tooltip right z-index="999" nudge-right="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <div class="space-img">
                          <div
                            style="height: 100%; width: 100%"
                            v-viewer
                            @click.stop
                            v-if="item.imageUrl != null && item.imageUrl != ''"
                          >
                            <img
                              :key="item.id + '_img'"
                              :src="item.imageUrl"
                              height="100%"
                              width="100%"
                            />
                          </div>
                          <i v-else :class="'fad fa-vector-square'"></i>
                        </div>
                      </div>
                    </template>
                    <span>
                      <v-img
                        v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                        class="img"
                        :src="item.imageUrl"
                        height="250px"
                        width="250px"
                        contain
                      ></v-img>
                      <i
                        v-else
                        :class="'fad fa-vector-square'"
                        style="font-size: 32px; padding: 2rem"
                      ></i>
                    </span>
                  </v-tooltip>
                </v-badge>
              </template>
              <template v-slot:[`item.client`]="{ item }">
                <v-tooltip v-if="item.client" top nudge-top="-16px">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="pa-2"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="viewClient(item.client.id)"
                    >
                      <v-img
                        v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                        :src="item.client.logoUrl"
                        max-height="28"
                        max-width="54"
                        position="left center"
                        contain
                      >
                      </v-img>
                      <v-img
                        v-else
                        src="/img/DNA_Symbol.png"
                        max-height="28"
                        max-width="54"
                        position="left center"
                        contain
                      >
                      </v-img>
                    </div>
                  </template>
                  <span>{{ item.client.name }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <strong>{{ item.name }}</strong>
              </template>
              <template v-slot:[`item.equipmentCount`]="{ item }">
                <equipment-count :count="item.equipmentCount" />
              </template>
              <template v-slot:no-data>
                <img width="500" src="/img/art/fogg-no-connection-2.png" />
                <p class="font-weight-bold">No Data Available!</p>
              </template>
              <template v-slot:loading>
                <v-progress-circular
                  class="mt-8"
                  indeterminate
                  color="orange"
                  size="40"
                  width="3"
                ></v-progress-circular>
                <p class="font-weight-bold mt-4">Loading data...</p>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <div
      v-else
      key="no-widget-perms"
      class="d-flex flex-column align-center justify-center"
      style="width: 100%; height: 100%; min-height: 64vh"
    >
      <div class="area">
        <ul class="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <span class="main-dash-icon-wrapper">
        <i class="fad fa-satellite opacity-54 floating main-dash-icon" style="font-size: 12rem"></i>
      </span>
      <h2 class="mt-6 font-weight-black opacity-72 fs-22px">Welcome to Concordia!</h2>
      <p class="mt-1 font-weight-medium opacity-54 fs-16px">
        You don't have available widgets to view yet!
      </p>
    </div>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import projectService from "../../Projects/services/projects-service";
import manufactureService from "../../Companies/Manufacturers/services/manufacturers-service";
import spaceService from "../../Spaces/services/spaceTemplates-service";
import equipmentService from "../../Equipments/services/equipments-service";
import ClientCountChart from "../components/ClientCountChart.vue";
import projectHeader from "../../Projects/config/tables/projectSummary.header";
import starredProjectsHeader from "../../Projects/config/tables/starredProjectsSummary.header";
import spaceTemplatesHeader from "../../Spaces/config/tables/spaceTemplateSummary.header";
import equipmentHeader from "../../Equipments/config/tables/equipmentSummary.header";
import manufactureHeader from "../../Companies/Manufacturers/config/tables/manufacturesSummary.header";
import ProjectStatus from "../../Projects/components/ProjectStatus.vue";
// import PhaseByDeadline from "../components/phaseByDeadline.vue";
import ProjectsCountPieChart from "../components/ProjectsCountPieChart.vue";
import OverviewEntryCard from "../components/OverviewEntryCard.vue";

export default {
  components: {
    ClientCountChart,
    ProjectStatus,
    // PhaseByDeadline,
    ProjectsCountPieChart,
    OverviewEntryCard,
  },
  data() {
    return {
      specialManufacturerName: "**NO ASSOCIATED MAKE**",
      perms: perms,
      hourNow: 0,
      rand: 1,
      systemOverviewStats: null,
      loadingSystemOverviewStats: true,
      projectHeader,
      starredProjectsHeader,
      spaceTemplatesHeader,
      equipmentHeader,
      manufactureHeader,
      PROJECTS_RECENT_TYPE: Object.freeze({
        Opened: 1,
        Updated: 2,
        Created: 3,
      }),
      EQUIPMENTS_RECENT_TYPE: Object.freeze({
        Updated: 1,
        Created: 2,
      }),
      projects: {
        loading: true,
        active: null,
        mode: 1,
        data: [],
      },
      starredProjects: {
        loading: true,
        active: null,
        mode: 1,
        data: [],
      },
      equipment: {
        loading: true,
        active: null,
        mode: 1,
        data: [],
      },
      manufacture: {
        loading: true,
        active: null,
        mode: 1,
        data: [],
      },
      spaceTemplates: {
        loading: true,
        active: null,
        mode: 1,
        data: [],
      },
    };
  },
  created() {
    this.rand = Math.random() > 0.5 ? 1 : 0;
  },
  mounted() {
    this.updateNow();
    setInterval(this.updateNow.bind(this), 1000);
    if (this.$has(perms.Dashboard.ViewAllWidgets)) {
      this.getRecentEquipment();
      this.getSystemOverviewStats();
      this.getRecentProjects();
      this.getStarredProjects();
      this.getRecentManufacture();
      this.getRecentSpaceTemplates();
    }
  },
  methods: {
    getSystemOverviewStats() {
      this.loadingSystemOverviewStats = true;
      projectService
        .systemOverviewStats()
        .then((res) => {
          this.systemOverviewStats = res.data;
          this.$log("systemOverviewStats", this.systemOverviewStats);
          this.loadingSystemOverviewStats = false;
        })
        .catch((err) => {
          this.loadingSystemOverviewStats = false;
        });
    },
    updateNow() {
      this.hourNow = new Date().getHours();
    },
    projectClicked(row) {
      this.$router.replace(`/projects/${row.id}`);
    },
    starredProjectClicked(row) {
      this.$router.replace(`/projects/${row.id}`);
    },
    equipmentClicked(row) {
      this.$router.replace(`/equipment/${row.id}`);
    },
    manufactureClicked(row) {
      this.$router.replace(`/manufacturers/${row.id}`);
    },
    templateClicked(row) {
      this.$router.replace(`/space-templates/${row.id}`);
    },
    getRecentEquipment() {
      this.equipment.loading = true;
      equipmentService
        .getRecent(this.equipment.mode, 10)
        .then((resp) => {
          this.equipment.data = resp.data;
          this.equipment.loading = false;
        })
        .catch((err) => {
          this.equipment.loading = false;
        });
    },
    getRecentManufacture() {
      this.manufacture.loading = true;
      manufactureService
        .getRecent(this.manufacture.mode, 10)
        .then((resp) => {
          this.manufacture.data = resp.data;
          this.manufacture.loading = false;
        })
        .catch((err) => {
          this.manufacture.loading = false;
        });
    },
    getRecentProjects() {
      this.projects.loading = true;
      projectService
        .getRecent(this.projects.mode, 10)
        .then((resp) => {
          this.projects.data = resp.data;
          this.projects.loading = false;
        })
        .catch((err) => {
          this.projects.loading = false;
          this.$handleError(err);
        });
    },
    getStarredProjects() {
      this.starredProjects.loading = true;
      projectService
        .getStarredProjects()
        .then((resp) => {
          this.starredProjects.data = resp.data;
          this.starredProjects.loading = false;
        })
        .catch((err) => {
          this.starredProjects.loading = false;
          this.$handleError(err);
        });
    },
    getRecentSpaceTemplates() {
      this.spaceTemplates.loading = true;
      spaceService
        .getRecent(this.spaceTemplates.mode, 10)
        .then((resp) => {
          this.spaceTemplates.data = resp.data;
          this.spaceTemplates.loading = false;
        })
        .catch((err) => {
          this.spaceTemplates.loading = false;
          this.$handleError(err);
        });
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.Dashboard.Update);
    },
    recentEquipment() {
      return this.equipment.data;
    },
    recentManufactures() {
      return this.manufacture.data;
    },
    recentProjects() {
      return this.projects.data;
    },
    recentSpaceTemplates() {
      return this.spaceTemplates.data;
    },
    dashboardGreetings() {
      return "Welcome Back,";
    },
    timeGreetings() {
      if (this.hourNow > 18) {
        return "Good evening,";
      } else if (this.hourNow > 12) {
        return "Good afternoon,";
      } else if (this.hourNow > 0) {
        return "Good morning,";
      } else {
        return "Welcome Back,";
      }
    },
  },
  watch: {
    "projects.mode": {
      handler() {
        this.getRecentProjects();
      },
      deep: true,
    },
    "equipment.mode": {
      handler() {
        this.getRecentEquipment();
      },
      deep: true,
    },
    "manufacture.mode": {
      handler() {
        this.getRecentManufacture();
      },
      deep: true,
    },
    "spaceTemplates.mode": {
      handler() {
        this.getRecentSpaceTemplates();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.dashboard-card-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.apexcharts-legend-series {
  display: flex;
  gap: 2px;
  align-items: center;
}

.dashboard-card-table {
  overflow: auto;
  overflow: overlay;
}

.v-slide-group .v-slide-group__prev .v-icon,
.v-slide-group .v-slide-group__next .v-icon {
  color: rgba($shades-black, 0.87);
}

.v-slide-group .v-slide-group__prev.v-slide-group__prev--disabled .v-icon.v-icon--disabled,
.v-slide-group .v-slide-group__next.v-slide-group__next--disabled .v-icon.v-icon--disabled {
  color: rgba($shades-black, 0.16) !important;
}

.type-label {
  font-weight: 700 !important;
  font-size: 16px !important;

  a {
    font-weight: 700 !important;
    font-size: 16px !important;
    text-decoration: none;
    color: rgba($shades-black, 0.87) !important;

    &:hover {
      text-decoration: underline !important;
    }
  }
}

.type-recently-label {
  margin-left: 0.5rem;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 32px;
}

.v-select__selection--comma {
  margin: 3px 0px 3px 0 !important;
  overflow: visible !important;
  // color: $primary-base !important;
}

.type-recently-label,
.v-select__selection,
.v-list-item__title {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

.type-select {
  display: inline-block;
  width: min-content;
  margin-left: 0.25rem;

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    min-height: 32px !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
    // box-shadow: none !important;

    .v-select__slot {
      height: 32px;
    }
  }

  .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:not(:hover) {
    background-color: transparent !important;
  }
}

.space-card-img {
  border: 0.5px solid rgba($shades-black, 0.24);
  border-left: none;
  border-right: none;
  border-radius: 0 !important;
  // background: rgba($shades-black, 0.04);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 0.25rem;
  background: #f8f9f9;

  .v-responsive__sizer {
    display: none !important;
  }

  i {
    font-size: 24px;
    opacity: 0.54;
  }
}

.templates-table,
.projects-table {
  tbody tr {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.floating {
  animation-name: floating;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  64% {
    transform: translate(0, 30px);
  }
  to {
    transform: translate(0, -0px);
  }
}
.area {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba($shades-black, 0.08);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 5%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
</style>
